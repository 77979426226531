import { useContext, useState } from 'react';

import {
  ListSubheader,
  alpha,
  Box,
  List,
  styled,
  Button,
  ListItem,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ListItemIcon
} from '@mui/material';
import { NavLink as RouterLink, useNavigate } from 'react-router-dom';
import Flag from 'react-flagkit';
import { useDispatch, useSelector } from 'react-redux';

import { SidebarContext } from 'src/contexts/SidebarContext';

import { rlsEnvDetails } from 'src/data/Rls';

import DesignServicesTwoToneIcon from '@mui/icons-material/DesignServicesTwoTone';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import PeopleAltTwoToneIcon from '@mui/icons-material/PeopleAltTwoTone';
import MonitorHeartTwoToneIcon from '@mui/icons-material/MonitorHeartTwoTone';
import TerminalTwoToneIcon from '@mui/icons-material/TerminalTwoTone';
import MeetingRoomTwoToneIcon from '@mui/icons-material/MeetingRoomTwoTone';
import GroupTwoToneIcon from '@mui/icons-material/GroupTwoTone';
import { AiOutlineCloudSync } from 'react-icons/ai';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import LiveTvTwoToneIcon from '@mui/icons-material/LiveTvTwoTone';
import HealthAndSafetyTwoToneIcon from '@mui/icons-material/HealthAndSafetyTwoTone';
import CampaignTwoToneIcon from '@mui/icons-material/CampaignTwoTone';
import AttractionsTwoToneIcon from '@mui/icons-material/AttractionsTwoTone';
import BalconyTwoToneIcon from '@mui/icons-material/BalconyTwoTone';

import hasAccess from 'src/utils/usefulFunc/hasAccess';

import {
  setAccessFormOpen,
  setRequestedModifyAccessControl
} from 'src/store/reducers/userCustomData';

const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    padding: ${theme.spacing(1)};

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
    }
  }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.colors.alpha.trueWhite[50]};
      padding: ${theme.spacing(0, 2.5)};
      line-height: 1.4;
    }
`
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {

      .MuiListItem-root {
        padding: 1px 0;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }
    
        .MuiButton-root {
          display: flex;
          color: ${theme.colors.alpha.trueWhite[70]};
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(1.2, 3)};

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${theme.colors.alpha.trueWhite[30]};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
          }
          
          .MuiButton-endIcon {
            color: ${theme.colors.alpha.trueWhite[50]};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          &.active,
          &:hover {
            background-color: ${alpha(theme.colors.alpha.trueWhite[100], 0.06)};
            color: ${theme.colors.alpha.trueWhite[100]};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.trueWhite[100]};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3)};

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &:before {
                content: ' ';
                background: ${theme.colors.alpha.trueWhite[100]};
                opacity: 0;
                transition: ${theme.transitions.create([
                  'transform',
                  'opacity'
                ])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }

              &.active,
              &:hover {

                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`
);

const ListItemIconWrapper = styled(ListItemIcon)(
  ({ theme }) => `
        min-width: 36px;
        color: ${theme.colors.primary.dark};
`
);

const AccordionSummaryWrapper = styled(AccordionSummary)(
  ({ theme }) => `
        &.Mui-expanded {
          min-height: 30px;
        }

        .MuiAccordionSummary-content.Mui-expanded {
          margin: 12px 0;
        }

        .MuiSvgIcon-root {
          transition: ${theme.transitions.create(['color'])};
        }

        &.MuiButtonBase-root {
          &:last-child {
            margin-bottom: 0;
          }

          &.Mui-expanded,
          &:hover {
            background: ${theme.colors.alpha.trueWhite[10]} !important;

            .MuiSvgIcon-root {
              color: ${theme.colors.primary.main} !important;
            }
          }
        }
        border-radius:9px;
       
`
);

function SidebarMenu() {
  const { closeSidebar } = useContext(SidebarContext);
  const { accessControl } = useSelector((state) => state.userCustomData);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [environmentExpanded, setEnvironmentExpanded] = useState(false);

  const handleEnvironmentChange = (panel) => (event, isExpanded) => {
    setEnvironmentExpanded(isExpanded ? panel : false);
    if (isExpanded) {
      navigate(`/rls/${panel}/environment-details`);
    }
  };

  return (
    <>
      <MenuWrapper>
        <List component="div">
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/overview"
                  startIcon={<DesignServicesTwoToneIcon />}
                >
                  Home page
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>
        <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
              RLS Environments
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              {rlsEnvDetails.map((env) => (
                <Accordion
                  sx={{ width: '100%', background: 'inherit' }}
                  key={env.pageLink}
                  expanded={environmentExpanded === env.pageLink}
                  onChange={handleEnvironmentChange(env.pageLink)}
                  disabled={
                    !hasAccess(
                      `${env.pageLink}-${env.resourceCode}`,
                      accessControl,
                      ['read', 'write', 'admin']
                    )
                  }
                >
                  <AccordionSummaryWrapper
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                      background: 'inherit'
                    }}
                  >
                    <ListItemIconWrapper>
                      <Flag country={env.primaryCountryCode} />
                    </ListItemIconWrapper>
                    <Typography sx={{ fontWeight: 'bold' }} variant="subtitle">
                      {env.displayName} - {env.resourceCode}
                    </Typography>
                  </AccordionSummaryWrapper>

                  <AccordionDetails sx={{ p: 0 }}>
                    <List component="div">
                      <ListItem component="div">
                        <Button
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to={`/rls/${env.pageLink}/environment-details`}
                          startIcon={<InfoTwoToneIcon color="info" />}
                          sx={{ fontWeight: 'bold' }}
                        >
                          Environment Details
                        </Button>
                      </ListItem>
                      <ListItem component="div">
                        <Button
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to={`/rls/${env.pageLink}/customers`}
                          startIcon={<PeopleAltTwoToneIcon color="warning" />}
                        >
                          Customers
                        </Button>
                      </ListItem>
                      <ListItem component="div">
                        <Button
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to={`/rls/${env.pageLink}/monitoring`}
                          startIcon={<MonitorHeartTwoToneIcon color="error" />}
                        >
                          Monitoring
                        </Button>
                      </ListItem>
                    </List>
                  </AccordionDetails>
                </Accordion>
              ))}
            </List>
          </SubMenuWrapper>
        </List>
        <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
              Turbo
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  startIcon={<HealthAndSafetyTwoToneIcon color="success" />}
                  component={RouterLink}
                  onClick={closeSidebar}
                  to={`/turbo/health-checks`}
                >
                  Health Checks
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>
        <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
              Status Page
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  startIcon={<LiveTvTwoToneIcon color="warning" />}
                  component={RouterLink}
                  onClick={closeSidebar}
                  to={`/status-page/internal-status-page`}
                >
                  Internal Status Page
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>

        <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
              Cloudops Home
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              <Accordion
                sx={{ width: '100%', background: 'inherit' }}
                disabled={
                  !hasAccess('cloudops-portal', accessControl, ['admin'])
                }
              >
                <AccordionSummaryWrapper
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    background: 'inherit'
                  }}
                >
                  <ListItemIconWrapper>
                    <AiOutlineCloudSync fontSize="1.7em" fontWeight="bold" />
                  </ListItemIconWrapper>
                  <Typography sx={{ fontWeight: 'bold' }} variant="subtitle">
                    Cloudops Portal
                  </Typography>
                </AccordionSummaryWrapper>

                <AccordionDetails sx={{ p: 0 }}>
                  <List component="div">
                    <ListItem
                      component="div"
                      disabled={
                        !hasAccess('cloudops-portal', accessControl, ['admin'])
                      }
                    >
                      <Button
                        disableRipple
                        component={RouterLink}
                        onClick={closeSidebar}
                        to={`/cloudops-portal/access-requests`}
                        startIcon={<MeetingRoomTwoToneIcon color="info" />}
                        sx={{ fontWeight: 'bold' }}
                        disabled={
                          !hasAccess('cloudops-portal', accessControl, [
                            'admin'
                          ])
                        }
                      >
                        Access Requests
                      </Button>
                    </ListItem>
                    <ListItem
                      component="div"
                      disabled={
                        !hasAccess('cloudops-portal', accessControl, ['admin'])
                      }
                    >
                      <Button
                        disableRipple
                        component={RouterLink}
                        onClick={closeSidebar}
                        to={`/cloudops-portal/users`}
                        startIcon={<GroupTwoToneIcon color="success" />}
                        disabled={
                          !hasAccess('cloudops-portal', accessControl, [
                            'admin'
                          ])
                        }
                      >
                        All Users
                      </Button>
                    </ListItem>
                    <ListItem
                      component="div"
                      disabled={
                        !hasAccess('cloudops-portal', accessControl, ['admin'])
                      }
                    >
                      <Button
                        disableRipple
                        component={RouterLink}
                        onClick={closeSidebar}
                        to={`/cloudops-portal/trigger-builds`}
                        startIcon={<TerminalTwoToneIcon color="warning" />}
                        disabled={
                          !hasAccess('cloudops-portal', accessControl, [
                            'admin'
                          ])
                        }
                      >
                        Trigger Builds
                      </Button>
                    </ListItem>

                    <SubMenuWrapper>
                      <List component="div">
                        <Accordion
                          sx={{ width: '100%', background: 'inherit' }}
                          disabled={
                            !hasAccess('cloudops-portal', accessControl, [
                              'admin'
                            ])
                          }
                        >
                          <AccordionSummaryWrapper
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={{
                              background: 'inherit'
                            }}
                          >
                            <ListItemIconWrapper>
                              <CampaignTwoToneIcon color="primary" />
                            </ListItemIconWrapper>
                            <Typography
                              sx={{ fontWeight: 'bold' }}
                              variant="subtitle"
                            >
                              Notifications
                            </Typography>
                          </AccordionSummaryWrapper>

                          <AccordionDetails sx={{ p: 0 }}>
                            <List component="div">
                              <ListItem
                                component="div"
                                disabled={
                                  !hasAccess('cloudops-portal', accessControl, [
                                    'admin'
                                  ])
                                }
                              >
                                <Button
                                  disableRipple
                                  component={RouterLink}
                                  onClick={closeSidebar}
                                  to={`/cloudops-portal/notification/rls-notification`}
                                  startIcon={
                                    <AttractionsTwoToneIcon color="warning" />
                                  }
                                  sx={{ fontWeight: 'bold' }}
                                  disabled={
                                    !hasAccess(
                                      'cloudops-portal',
                                      accessControl,
                                      ['admin']
                                    )
                                  }
                                >
                                  RLS Notification
                                </Button>
                              </ListItem>
                              <ListItem
                                component="div"
                                disabled={
                                  !hasAccess('cloudops-portal', accessControl, [
                                    'admin'
                                  ])
                                }
                              >
                                <Button
                                  disableRipple
                                  component={RouterLink}
                                  onClick={closeSidebar}
                                  to={`/cloudops-portal/users`}
                                  startIcon={
                                    <BalconyTwoToneIcon color="success" />
                                  }
                                  disabled={
                                    !hasAccess(
                                      'cloudops-portal',
                                      accessControl,
                                      ['admin']
                                    )
                                  }
                                >
                                  Turbo Reboots
                                </Button>
                              </ListItem>
                            </List>
                          </AccordionDetails>
                        </Accordion>
                      </List>
                    </SubMenuWrapper>
                  </List>
                </AccordionDetails>
              </Accordion>
            </List>
          </SubMenuWrapper>
        </List>
        <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
              Access Control
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              <ListItem
                component="div"
                disabled={!accessControl || accessControl?.length === 0}
              >
                <Button
                  disableRipple
                  startIcon={<LockOpenTwoToneIcon />}
                  disabled={!accessControl || accessControl?.length === 0}
                  onClick={() => {
                    dispatch(setAccessFormOpen(true));
                    dispatch(setRequestedModifyAccessControl(true));
                  }}
                >
                  My Access
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>
      </MenuWrapper>
    </>
  );
}

export default SidebarMenu;
