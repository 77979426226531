/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getRlsOrgAnalytics = /* GraphQL */ `
  query GetRlsOrgAnalytics($customerId: String!, $date: AWSDate!) {
    getRlsOrgAnalytics(customerId: $customerId, date: $date) {
      id
      customerId
      date
      time
      organizationId
      organizationFriendlyId
      environmentCode
      activeUsers
      inActiveUsers
      deletedUsers
      roles
      apiConnections
      customerCodes
      trustedApis
      trustedObjects
      apiResources
      objects
      categories
      schedulers
      dataSyncStatus
      environment

      __typename
    }
  }
`;

export const getRlsOrgAnalyticsExternalIntegrationsOnly = /* GraphQL */ `
  query GetRlsOrgAnalytics($customerId: String!, $date: AWSDate!) {
    getRlsOrgAnalytics(customerId: $customerId, date: $date) {
      salesforceConnectionStatus
      platformConnectionStatus
      postgresConnectionStatus
      __typename
    }
  }
`;
export const listRlsOrgAnalytics = /* GraphQL */ `
  query ListRlsOrgAnalytics(
    $customerId: String
    $date: ModelStringKeyConditionInput
    $filter: ModelRlsOrgAnalyticsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRlsOrgAnalytics(
      customerId: $customerId
      date: $date
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        customerId
        date
        time
        organizationId
        organizationFriendlyId
        environmentCode
        activeUsers
        inActiveUsers
        deletedUsers
        roles
        apiConnections
        customerCodes
        trustedApis
        trustedObjects
        apiResources
        objects
        categories
        schedulers
        dataSyncStatus
        environment

        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const listRlsOrgAnalyticsUserCount = /* GraphQL */ `
  query ListRlsOrgAnalytics(
    $customerId: String
    $date: ModelStringKeyConditionInput
    $filter: ModelRlsOrgAnalyticsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRlsOrgAnalytics(
      customerId: $customerId
      date: $date
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        date
        activeUsers
        inActiveUsers
        deletedUsers
        roles
        apiConnections

        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRlsEnvAnalytics = /* GraphQL */ `
  query GetRlsEnvAnalytics($environment: String!, $date: AWSDate!) {
    getRlsEnvAnalytics(environment: $environment, date: $date) {
      id
      environment
      date
      time
      environmentCode
      activeCustomersCount
      inActiveCustomersCount
      idpCustomersCount
      nonIdpCustomersCount
      trialOrgCount
      nonTrialOrgCount
      accountsCount
      releasesCount
      licenses
      accounts
      availableShards
      __typename
    }
  }
`;

export const getRlsEnvAnalyticsShardsOnly = /* GraphQL */ `
  query GetRlsEnvAnalytics($environment: String!, $date: AWSDate!) {
    getRlsEnvAnalytics(environment: $environment, date: $date) {
      id
      date
      time
      availableShards
      __typename
    }
  }
`;

export const getRlsOrgData = /* GraphQL */ `
  query GetRlsOrgData($customerId: String!) {
    getRlsOrgData(customerId: $customerId) {
      id
      customerId
      organizationId
      organizationFriendlyId
      environmentCode
      offboardingRequested
      offboardingRequestedBy
      offboardingStatus
      environment
      __typename
    }
  }
`;

export const getRlsEnvData = /* GraphQL */ `
  query GetRlsEnvData($environment: String!) {
    getRlsEnvData(environment: $environment) {
      id
      environment
      environmentCode
      environmentSwaggerRoutes
      uiUrls
      __typename
    }
  }
`;

export const getRlsInactiveOrgs = /* GraphQL */ `
  query GetRlsEnvData($environment: String!) {
    getRlsEnvData(environment: $environment) {
      id
      environment
      environmentCode
      inactiveOrgs
      __typename
    }
  }
`;

export const listRlsEnvAnalytics = /* GraphQL */ `
  query ListRlsEnvAnalytics(
    $environment: String
    $date: ModelStringKeyConditionInput
    $filter: ModelRlsEnvAnalyticsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRlsEnvAnalytics(
      environment: $environment
      date: $date
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        environment
        date
        time
        environmentCode
        activeCustomersCount
        inActiveCustomersCount
        idpCustomersCount
        nonIdpCustomersCount
        trialOrgCount
        nonTrialOrgCount
        accountsCount
        releasesCount
        licenses
        accounts
        availableShards

        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const listRlsEnvAnalyticsCountOnly = /* GraphQL */ `
  query ListRlsEnvAnalytics(
    $environment: String
    $date: ModelStringKeyConditionInput
    $filter: ModelRlsEnvAnalyticsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRlsEnvAnalytics(
      environment: $environment
      date: $date
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        date
        time
        activeCustomersCount
        inActiveCustomersCount
        idpCustomersCount
        nonIdpCustomersCount
        trialOrgCount
        nonTrialOrgCount
        accountsCount
        releasesCount

        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const listRlsEnvAnalyticsLicensesOnly = /* GraphQL */ `
  query ListRlsEnvAnalytics(
    $environment: String
    $date: ModelStringKeyConditionInput
    $filter: ModelRlsEnvAnalyticsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRlsEnvAnalytics(
      environment: $environment
      date: $date
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        date
        licenses

        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const listRlsEnvAnalyticsAccountsOnly = /* GraphQL */ `
  query ListRlsEnvAnalytics(
    $environment: String
    $date: ModelStringKeyConditionInput
    $filter: ModelRlsEnvAnalyticsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRlsEnvAnalytics(
      environment: $environment
      date: $date
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        date
        accounts

        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const listRlsEnvAnalyticsShardsOnly = /* GraphQL */ `
  query ListRlsEnvAnalytics(
    $environment: String
    $date: ModelStringKeyConditionInput
    $filter: ModelRlsEnvAnalyticsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRlsEnvAnalytics(
      environment: $environment
      date: $date
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        date
        availableShards

        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const rlsOrgAnalyticsByEnvironment = /* GraphQL */ `
  query RlsOrgAnalyticsByEnvironment(
    $environment: String!
    $sortDirection: ModelSortDirection
    $filter: ModelRlsOrgAnalyticsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    rlsOrgAnalyticsByEnvironment(
      environment: $environment
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerId
        date
        time
        organizationId
        organizationFriendlyId
        environmentCode
        activeUsers
        inActiveUsers
        deletedUsers
        roles
        apiConnections
        customerCodes
        trustedApis
        trustedObjects
        apiResources
        objects
        categories
        schedulers
        dataSyncStatus
        environment

        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      username
      email
      firstTimeLogedIn
      firstTimeRequestedAccessControl
      accessControlApplied
    }
  }
`;
export const getUserByEmail = /* GraphQL */ `
  query GetUserByEmail($email: AWSEmail!) {
    getUserByEmail(email: $email) {
      items {
        id
        username
        email
        firstTimeLogedIn
        firstTimeRequestedAccessControl
        accessControlApplied
      }
      nextToken
    }
  }
`;

export const getAppliedAccessControl = /* GraphQL */ `
  query GetAppliedAccessControl($userId: ID!) {
    getAppliedAccessControl(userId: $userId) {
      accessDetails
      created
    }
  }
`;

export const getRequestControlByUserIdAndStatus = /* GraphQL */ `
  query GetRequestControlByUserIdAndStatus($created: Boolean!) {
    listRequestedAccessControls(filter: { created: { eq: $created } }) {
      items {
        userId
        requestDetails
        created
        user {
          id
          username
          email
          firstTimeLogedIn
          firstTimeRequestedAccessControl
          accessControlApplied
        }
      }
    }
  }
`;

export const listUsersWithCount = /* GraphQL */ `
  query ListUsersWithCount($limit: Int, $nextToken: String) {
    listUsersWithCount(limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        email
        firstTimeLogedIn
        firstTimeRequestedAccessControl
        accessControlApplied
      }
      nextToken
    }
  }
`;

export const getTotalUserCount = /* GraphQL */ `
  query GetTotalUserCount {
    getTotalUserCount
  }
`;

export const getTidbMigrationGroupQuery = /* GraphQL */ `
  query GetTidbMigrationGroup($id: ID!) {
    getTidbMigrationGroup(id: $id) {
      id
      environment
      tidbDatabaseName
      infraId
      isStandaloneDatabase
      createdAt
      createdBy
      migrationCompletedOn
      status
      notificationConfiguration {
        sendNotificationPriorToMigration
        sendNotificationAfterMigration
        sendNotificationPriorToDataValidation
        sendNotificationAfterDataValidation
        notificationUserbase
      }
      customers {
        items {
          id
          customerId
          orgFid
          orgId
          migrationStatus
          environment
          currentInstanceURL
          forceMigration
          sendNotificationPriorToMigration {
            state
            startedAt
            completedAt
          }
          sendNotificationAfterMigration {
            state
            startedAt
            completedAt
          }
          sendNotificationPriorToDataValidation {
            state
            startedAt
            completedAt
          }
          sendNotificationAfterDataValidation {
            state
            startedAt
            completedAt
          }
          datamigrationStatus {
            state
            startedAt
            completedAt
            trackingId
          }
          dataValidationAndSwitchOverStatus {
            state
            startedAt
            completedAt
            trackingId
          }
        }
      }
    }
  }
`;

export const getAllTidbMigrationGroupsAndCustomers = /* GraphQL */ `
  query ListTidbMigrationGroupsByEnv(
    $environment: String!
    $sortDirection: ModelSortDirection
    $limit: Int
    $nextToken: String
  ) {
    tidbMigrationGroupsByEnvironmentAndCreatedAt(
      environment: $environment
      sortDirection: $sortDirection
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        environment
        tidbDatabaseName
        infraId
        isStandaloneDatabase
        createdAt
        updatedAt
        migrationCompletedOn
        createdBy
        status
        notificationConfiguration {
          sendNotificationPriorToMigration
          sendNotificationAfterMigration
          sendNotificationPriorToDataValidation
          sendNotificationAfterDataValidation
          notificationUserbase
        }
        customers {
          items {
            customerId
            orgFid
            orgId
            migrationStatus
            currentInstanceURL
            sendNotificationPriorToMigration {
              state
              startedAt
              completedAt
            }
            sendNotificationAfterMigration {
              state
              startedAt
              completedAt
            }
            sendNotificationPriorToDataValidation {
              state
              startedAt
              completedAt
            }
            sendNotificationAfterDataValidation {
              state
              startedAt
              completedAt
            }
            datamigrationStatus {
              state
              startedAt
              completedAt
              trackingId
            }
            dataValidationAndSwitchOverStatus {
              state
              startedAt
              completedAt
              trackingId
            }
            forceMigration
            environment
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const getAllTidbMigrationGroupsAndCustomersWithFilter = /* GraphQL */ `
  query ListTidbMigrationGroupsByEnv(
    $environment: String!
    $sortDirection: ModelSortDirection
    $limit: Int
    $nextToken: String
    $filter: ModelTidbMigrationGroupFilterInput
  ) {
    tidbMigrationGroupsByEnvironmentAndCreatedAt(
      environment: $environment
      sortDirection: $sortDirection
      limit: $limit
      nextToken: $nextToken
      filter: $filter
    ) {
      items {
        id
        environment
        tidbDatabaseName
        infraId
        isStandaloneDatabase
        createdAt
        updatedAt
        migrationCompletedOn
        createdBy
        status
        notificationConfiguration {
          sendNotificationPriorToMigration
          sendNotificationAfterMigration
          sendNotificationPriorToDataValidation
          sendNotificationAfterDataValidation
          notificationUserbase
        }
        customers {
          items {
            customerId
            orgFid
            orgId
            migrationStatus
            currentInstanceURL
            sendNotificationPriorToMigration {
              state
              startedAt
              completedAt
            }
            sendNotificationAfterMigration {
              state
              startedAt
              completedAt
            }
            sendNotificationPriorToDataValidation {
              state
              startedAt
              completedAt
            }
            sendNotificationAfterDataValidation {
              state
              startedAt
              completedAt
            }
            datamigrationStatus {
              state
              startedAt
              completedAt
              trackingId
            }
            dataValidationAndSwitchOverStatus {
              state
              startedAt
              completedAt
              trackingId
            }
            forceMigration
            environment
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const getAllTidbMigrationGroupsAndCustomersWithCustomersFilter = /* GraphQL */ `
  query ListTidbMigrationGroupsByCustomerId(
    $environment: String!
    $customerId: ID!
    $limit: Int
    $nextToken: String
  ) {
    tidbMigrationCustomersByEnvironmentAndCustomerId(
      environment: $environment
      customerId: { eq: $customerId }
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        group {
          id
          environment
          tidbDatabaseName
          infraId
          isStandaloneDatabase
          createdAt
          updatedAt
          migrationCompletedOn
          createdBy
          status
          notificationConfiguration {
            sendNotificationPriorToMigration
            sendNotificationAfterMigration
            sendNotificationPriorToDataValidation
            sendNotificationAfterDataValidation
            notificationUserbase
          }
          customers {
            items {
              customerId
              orgFid
              orgId
              migrationStatus
              currentInstanceURL
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const listCustomersWithCompletedValidation = /* GraphQL */ `
  query ListCustomersWithCompletedValidation(
    $filter: ModelTidbMigrationCustomerFilterInput
  ) {
    listTidbMigrationCustomers(filter: $filter) {
      items {
        customerId
        orgFid
        orgId
        migrationStatus
        currentInstanceURL
        dataValidationAndSwitchOverStatus {
          state
          startedAt
          completedAt
          trackingId
        }
      }
    }
  }
`;

export const listTurboHealthChecks = /* GraphQL */ `
  query ListTurboHealthChecks($limit: Int, $nextToken: String) {
    listTurboHealthChecks(limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        environmentId
        lastCheckedOn
        dataIntegrationHealthStatus
        dataAdminHealthStatus
        pricingHealthStatus
        dataIntegrationHealthResponse
        dataAdminHealthResponse
        pricingHealthResponse
        callVictorops
        healthCheckInterval
        disableIntegrationHealthCheck
        disableAdminHealthCheck
        disablePricingHealthCheck
      }
      nextToken
    }
  }
`;

export const listTurboHealthChecksSLAMetrics = /* GraphQL */ `
  query ListTurboHealthChecksSLAMetrics(
    $tenantId: String!
    $startDate: String
    $endDate: String
  ) {
    listTurboHealthChecksSLAMetrics(
      filter: {
        tenantId: { eq: $tenantId }
        intervalType: { eq: "day" }
        intervalKey: { between: [$startDate, $endDate] }
      }
    ) {
      items {
        healthyChecks
        id
        intervalKey
        intervalType
        lastUpdated
        tenantId
        totalChecks
      }
    }
  }
`;
